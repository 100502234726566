@import '../../variables.scss';

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.submitButton {
  margin: 10px 15px 0;
  width: calc(100% - 30px);
  height: 40px;

  background-color: $light-black;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
  inset 0 1px 0 $tan;
  border-radius: 4px;

  font-family: $electro;
  font-size: $subtitle-text;
  font-weight: 600;
  color: $scrib-gold;

  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.submitButton:active {
  background-color: $brown;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
  inset 0 1px 0 $brown;
  transform: scale(0.99);
}

.submitButton.submitButton--error {
  transform: translateY(15px);
}

.submitButton.submitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: $brown;
  box-shadow: none;
}