@import '../../variables.scss';

.mailchimp {
  padding: 120px;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr;
  color: $light-black;
  background-color: $tan;
  text-align: center;
  clear: left;

  h1, h2 {
    margin: 0;
  }

  h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-family: $electro;
    color: $scrib-gold;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 2vh;
    font-family: $montserrat;
    font-weight: 100;
  }

  .formRow {
    border-top: .5px solid $brown;
  }

  .formRowInput {
    border: none;
    color: $light-black;
  }

  .captcha {
    position: absolute;
    left: -5000px;
  }


  @media only screen and (max-width: 480px) {
    padding: 60px 20px;
    h1 {
      font-size: 3rem;
      font-weight: 800;
    }
  }
}