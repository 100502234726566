@import '../../variables.scss';

.counter {
  font-family: $montserrat;
  background-color: $tan;
  padding: 75px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @media only screen and (max-width : $xl-phones) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width : $phones) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10vw;
    row-gap: 7vh;
  }
}