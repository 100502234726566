@import '../../variables.scss';

.counter-with-caption {
  color: $brown;

  span{
    font-size: 2rem;
    font-family: $electro;
  }

  .caption {
    font-size: 1rem;
    text-transform: uppercase;
    font-family: $montserrat;
    font-weight: 800;
  }
}