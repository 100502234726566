@import '../../variables.scss';

.twitch {
  height: 70vh;
  font-family: $montserrat;
  background: linear-gradient(rgba(199, 160, 72, .95), rgba(199, 160, 72, .95)), url('https://storage.googleapis.com/dj-scrib.appspot.com/images/back.png');
  padding: 0 20vw;
  text-align: center;

  iframe {
    position: unset !important;
    width: 75vw;
    height: 60vh;
  }

  @media only screen and (max-width: $md-desktops) {
    text-align: center;
    padding: 20px;
  }
}
