@import '../../variables.scss';

.footer {
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr;
  font-family: $montserrat;
  color: $light-black;
  background-color: $scrib-gold;
  text-align: center;

  a {
    text-decoration: none;
    color: $light-black;
  }
}