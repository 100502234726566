@import "../../variables.scss";

.instagram {
  background: $light-black;
  color: $scrib-gold;
  font-family: $electro;
  padding: 40px 120px;

  h1 {
    font-size: 3rem;
  }

  .instagram-feed {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 12px 12px;
    align-items: stretch;
    justify-items: stretch;
    padding-bottom: 20px;
  }

  .divider{
    width: 30%;
    background-color: $scrib-gold;
    border: none;
    height: 1px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 40px 80px;

    .instagram-feed {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (max-width: 768px) {
    .instagram-feed {
      grid-template-columns: repeat(2, 1fr);
    }

    h1{
      font-size: 1.75rem;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 20px;

    .instagram-feed {
      grid-template-columns: 1fr;
    }

    h1{
      font-size: 1.5rem;
    }
  }
}
