$electro: 'Electro', sans-serif;
$montserrat: 'Montserrat', sans-serif;

$black: #0a0b0a;
$light-black: #3d3d3d;
$light-gold: #e4d9c1;
$scrib-gold: #c7a049;
$transparent-gold: rgba(199, 160, 72, .8);
$brown: #867f71;
$tan: #efebe0;
$white: #f6f6ed;
$light-white: #f5f3f5;

$box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);

$iphone: 320px;
$phones: 480px;
$xl-phones: 575px;
$tablet: 768px;
$md-desktops: 992px;
$lg-desktops: 1200px;

$subtitle-text: 1rem;
$sm-body-text: 1.25rem;
$body-text: 1.5rem;
$xs-header-text: 2rem;
$sm-header-text: 3rem;
$md-header-text: 4rem;
$lg-header-text: 5rem;
$xl-header-text: 15rem;


/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}