@import '../../variables.scss';

.publication {
  .publication-pic {
    width: 65%;
  }

  .publication-preview {
    text-transform: uppercase;

    .publication-header {
      font-family: $montserrat;
      font-size: .85rem;
      text-transform: uppercase;
      padding: 8px 16px 0 16px;
      margin: 0;
      color: $scrib-gold;
      max-width: 35vw;
      font-weight: 100;
    }

    .publication-date {
      margin: 0;
      font-size: .75rem;
    }

    .read-more {
      padding-top: 8px;
      font-size: .6rem;
      color: $black;
    }

    @media only screen and (max-width: $tablet) {
      .publication-header {
         max-width: 65%;
        font-size: .6rem;
      }
      .publication-date, .read-more {
        font-size: .45rem;
      }

      .read-more{
        padding-top: 4px;
      }
    }
  }
}