@import '../../variables.scss';

.team {
  background: url('https://storage.googleapis.com/dj-scrib.appspot.com/images/colorful.png');
  background-size: cover;
  background-position: center;
  opacity: .9;

  img {
    width: 25%;
    padding: 3vh 5vw;
  }
}