@import '../../variables.scss';

.header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5vw;

  background: url('https://storage.googleapis.com/dj-scrib.appspot.com/images/header.png') $scrib-gold;
  background-size: cover;
  box-shadow: $box-shadow;
  opacity: 0.75;

  color: $scrib-gold;
  text-align: left;

  .header-title, .header-subtitle {
    margin: 0;
    font-weight: 100;
  }

  .header-title {
    text-transform: uppercase;
    font-family: $montserrat;
    font-size: 9rem;
    font-weight: 800;

    line-height: .9;
    letter-spacing: -5px;
  }

  .header-subtitle {
    font-family: $electro;
    text-transform: uppercase;
    font-size: 3rem;
  }

  .social-icon-row {
    align-items: start;
    padding: 1rem 0;

    .social-icon {
      padding-right: 24px;
      width: 10%;
    }
  }

  @media only screen and (max-width: $tablet) {
    .header-title {
      font-size: 6rem;
    }
    .header-subtitle {
      font-size: 2rem;
    }
  }
}

@media only screen and (max-width: $phones) {
  .header {
    height: 100vh;
    background-image: url('https://storage.googleapis.com/dj-scrib.appspot.com/images/header-sm.png');
    opacity: 0.7;

    .header-title {
      font-size: 4rem;
      margin-left: 0;
    }

    .header-subtitle {
      font-size: 1.5rem;
    }
  }
}