.App {
  text-align: center;
}

.App-logo {
  width: 10%;
  height: auto;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #c7a049;
}

.center-content-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-content-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.shadow{
  -webkit-box-shadow: 2px 4px 19px 8px rgba(61,61,61,0.38);
  -moz-box-shadow: 2px 4px 19px 8px rgba(61,61,61,0.38);
  box-shadow: 2px 4px 19px 8px rgba(61,61,61,0.38);
}

.scrib-link{
  padding-top: 8px;
  text-decoration: none;
  text-transform: uppercase;
}

#root{
  overflow-x: hidden;
}

.formGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #c7a049;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
  inset 0 1px 0 #efebe0;
  border-radius: 4px;
}

.formRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #f5f3f5;
}

.formRow:first-child {
  border-top: none;
}
