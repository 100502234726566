@import '../../variables.scss';

.cofc-husbands {
  font-family: $montserrat;
  background: linear-gradient(rgba(199, 160, 72, .95), rgba(199, 160, 72, .95)), url('https://storage.googleapis.com/dj-scrib.appspot.com/images/husbands.png');
  padding: 15vh 20vw;
  text-align: center;

  .cofc-husbands-text {
    background: $tan;
    padding: 3vh 3vw;

    .cofc-husbands-header {
      padding: 0 50px;
      font-family: $electro;
      font-size: 3rem;
      margin-bottom: 0;
      color: $scrib-gold;
    }

    p {
      color: $light-black;
      font-size: 1.5rem;
    }

    .social-icon {
      padding: 0 24px;
      height: 8vh;
    }

    .videos{
      iframe{
        width: 75vw;
        height: 75vh;
        padding: 24px;
      }
    }
  }

  @media only screen and (max-width: $md-desktops) {
    text-align: center;
    padding: 20px;

    .cofc-husbands-text {
      .cofc-husbands-header {
        padding: 0 12px;
        font-size: 1.75rem;
      }

      p {
        padding: 0 10vw;
        font-size: .75rem;
      }

      .videos{
        iframe{
          width: 75vw;
          height: 50vh;
          padding: 24px;
        }
      }
    }
  }
}
