@import '../../variables.scss';

.about-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;

  background: url("https://storage.googleapis.com/dj-scrib.appspot.com/images/smile.png") $scrib-gold;
  background-size: cover;
  background-position: top;
  box-shadow: $box-shadow;
  opacity: 0.95;

  color: $scrib-gold;
  text-align: left;


  .about-header-title {
    margin: 0;
    text-transform: uppercase;
    font-family: $montserrat;
    font-size: 9rem;
    font-weight: 800;
    line-height: .8;
    letter-spacing: -5px;
  }

  @media only screen and (max-width: $tablet) {
    .about-header-title {
      font-size: 6rem;
    }
  }
}

@media only screen and (max-width: $phones) {
  .about-header {
    height: 100vh;

    .about-header-title {
      font-size: 4rem;
      margin-left: 0;
    }
  }
}