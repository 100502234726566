@import '../../variables.scss';

:root {
  --color-primary: #002626;
  --color-secondary: #F0F7EE;
  --duration: 1s;
  --nav-duration: calc(var(--duration) / 4);
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --space: 1rem;
  --font-heading: 'Merriweather', serif;
  --font-size: 1.125rem;
  --line-height: 1.5;
}

.scrib-nav {
  h1 {
    margin-bottom: calc(var(--space) * 3);
    font-family: $electro;
    font-size: calc(var(--font-size) + 6vmin);
    line-height: calc(var(--line-height) / 1.25);
  }

  .main-navigation-toggle {
    position: fixed;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;

    + label {
      position: fixed;
      top: calc(var(--space) * 1.5);
      right: calc(var(--space) * 2);
      cursor: pointer;
      z-index: 2;
    }
  }

  .icon--menu-toggle {
    --size: calc(1rem + 4vmin);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    stroke-width: 3px;
  }

  .icon-group {
    transform: translateX(0);
    transition: transform var(--nav-duration) var(--ease);
  }

  .icon--menu {
    stroke: $scrib-gold;
  }

  .icon--close {
    stroke: $light-black;
    transform: translateX(-100%);
  }

  .main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform var(--nav-duration);
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $scrib-gold;
      transform-origin: 0 50%;
      z-index: -1;
    }

    ul {
      font-size: 12vmin;
      font-family: var(--font-heading);
      width: 100%;
      padding: 0;
    }

    li {
      --border-size: 1vmin;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: var(--border-size);
        background-color: $light-black;
        transform-origin: 0 50%;
        transform: translateX(-100%) skew(15deg);
      }
    }

    a {
      font-family: $electro;
      font-weight: 700;
      display: inline-block;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      color: $light-black;
      line-height: 1;
      text-decoration: none;
      user-select: none;
      padding: var(--space) calc(var(--space) * 2) calc(var(--space) + var(--border-size) / 2);
      transform: translateY(100%);
    }
  }

  .main-navigation-toggle:checked {
    ~ label .icon--menu-toggle {
      .icon-group {
        transform: translateX(100%);
      }
    }

    ~ .main-content {
      transform: translateX(10%);
    }

    ~ .main-navigation {
      transition-duration: 0s;
      transform: translateX(0);

      &:after {
        animation: nav-bg var(--nav-duration) var(--ease) forwards;
      }

      li:after {
        animation: nav-line var(--duration) var(--ease) forwards;
      }

      a {
        animation: link-appear calc(var(--duration) * 1.5) var(--ease) forwards;
      }

      @for $i from 1 through 4 {
        li:nth-child(#{$i}) {
          &:after, a {
            animation-delay: calc((var(--duration) / 2) * #{$i} * 0.125);
          }
        }
      }
    }
  }

  @keyframes nav-bg {
    from {
      transform: translateX(-100%) skewX(-15deg)
    }
    to {
      transform: translateX(0)
    }
  }

  @keyframes nav-line {
    0% {
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
    35% {
      transform: scaleX(1.001);
      transform-origin: 0 50%;
    }
    65% {
      transform: scaleX(1.001);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes link-appear {
    0%, 25% {
      transform: translateY(100%);
    }
    50%, 100% {
      transform: translateY(0);
    }
  }
}