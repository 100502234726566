@import '../../variables.scss';

.contact {
  padding: 60px 10px;
  display: grid;
  grid-template-columns: 1.45fr 3fr .25fr;
  font-family: $montserrat;
  background: $tan;
  box-shadow: 2px 4px 9px 8px rgba(61,61,61,0.18);

  .contact-header, .contact-response {
    color: $scrib-gold;
    text-align: center;
  }

  .contact-header {
    font-family: $electro;
    font-size: 3rem;
  }

  .contact-response {
    font-family: $montserrat;
    font-weight: 100;
    font-size: 1.5rem;
    padding: 0 16px;
    color: $light-black;
  }

  .checkout-form-container {
    padding-top: 24px;
    width: 50vw;
    font-family: $electro;

    @media screen and (max-width: $xl-phones) {
      width: 98vw;
    }

    input, button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      border-style: none;
    }
  }

  @media only screen and (max-width: $tablet) {
    .contact-header {
      font-size: 1.5rem;
    }

    .contact-response {
      font-size: 1rem;
    }
  }
}