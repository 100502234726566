@import '../../variables.scss';

.home {
  .work-wrapper{
    display: grid;
    grid-template-columns: 2fr 3fr;

    @media only screen and (max-width: $xl-phones) {
      grid-template-columns: 1fr;
    }
  }
}