@import '../../variables.scss';

.masterpieces {
  background-color: $light-white;
  font-family: $montserrat;
  padding: 17.5vh 10vw;

  .masterpiece-header {
    font-family: $electro;
    font-size: 3rem;
    color: $scrib-gold;
  }

  .featured-mix-wrapper {
    .featured-mix {
      width: 50%;
      height: auto;
    }

    .mix-icons {
      padding: 5vh 30vw;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media only screen and (max-width: $md-desktops) {
        .featured-mix-icon {
          width: 50%;
        }
      }
    }
  }

  .masterpiece-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    padding: 0 10vw;

    .mix-wrapper {
      padding: 12px;
      align-self: center;

      img {
        width: 100%;
        height: auto;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
      }
    }
  }

  @media only screen and (max-width: $md-desktops) {
    padding: 20px 20px 75px 20px;

    .masterpiece-wrapper {
      grid-template-columns: 1fr 1fr;
      padding: 0;
    }
    .masterpiece-header {
      font-size: 2rem;
    }
  }
}