@import '../../variables.scss';

.news {
  font-family: $montserrat;
  display: grid;
  row-gap: 0;
  padding: 5vh 0;
  margin: 0;
  background-color: $light-white;
  overflow: hidden;

  .news-title {
    font-size: 2rem;
    font-family: $electro;
    text-transform: uppercase;
    color: $scrib-gold;
  }
}

.carousel .slide {
  background: $light-white !important;

  img {
    padding: 0 4px;
    width: 65%;
    height: auto;
  }
}

.carousel-root {
  padding: 0 40px;
  width: 75%;

  @media only screen and (max-width: $tablet) {
    padding: 0;
    width: 85%;
  }
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid $scrib-gold !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid $scrib-gold !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: unset !important;
}