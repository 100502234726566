@import '../../variables.scss';

.family-wrapper {
  font-family: $montserrat;
  background: $light-white;
  position: relative;

  .family-pic {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    img {
      height: auto;
      width: 100%;
      opacity: .6;
    }

    .airJourn {
      background-position: top;
    }
  }

  .family-text {
    box-shadow: $box-shadow;
    background: $tan;
    text-align: left;
    align-items: flex-start;

    .family-header, .family-body {
      margin: 0;
      font-family: $montserrat;
    }

    .family-header {
      font-family: $electro;
      text-transform: uppercase;
      color: $scrib-gold;
      text-align: left;
      font-size: 3rem;
    }

    .family-body {
      color: $light-black;
      font-size: 1rem;
      padding-top: 4vh;
    }

    @media only screen and (min-width: $tablet) {
      z-index: 1000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 5vh 5vw;
    }
  }

  @media only screen and (max-width: $tablet) {
    display: grid;
    grid-template-columns: 1fr;

    .family-pic {
      grid-template-columns: 1fr;

      img{
        opacity: 1;
      }

      .duplicate-image {
        display: none;
      }
    }

    .family-text{
      text-align: center;
      align-items: center;
      padding: 36px;

      .family-header{
        font-size: 1.75rem;
      }
    }
  }
}