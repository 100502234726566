@import '../../../variables.scss';

.about-text-wrapper {
  padding: 120px;
  display: grid;
  grid-template-columns: 1.45fr 3fr .25fr;
  font-family: $montserrat;

  .about-text-header {
    color: $scrib-gold;
    text-align: left;
    font-family: $electro;
    font-size: 3rem;
  }

  .about-text {
    text-align: justify;

    p {
      font-size: 1rem;
    }

    .scrib-link {
      font-size: .75rem;
      color: $scrib-gold;
    }
  }

  @media only screen and (max-width: $tablet) {
    grid-template-columns: 100%;
    padding: 50px;

    .about-text-header {
      text-align: center;
    }

    .about-text {
      text-align: center;

      p{
        text-align: justify;
      }
    }
  }
}