@import '../../variables.scss';

.bio {
  padding: 10vh 15vw;
  font-size: 1.25rem;
  font-family: $montserrat;
  text-align: justify;

  @media only screen and (max-width: $tablet) {
    font-size: .75rem;
  }
}