@import '../../variables.scss';

.mister-vickers-wrapper {
  font-family: $montserrat;
  display: grid;
  grid-template-columns: 30vw 70vw;

  .mister-vickers-text {
    background: $light-white;
    text-align: center;

    .mister-vickers-header {
      color: $scrib-gold;
      padding: 0 50px;
      text-align: left;
      font-family: $electro;
      font-size: 3rem;
      margin-bottom: 0;
    }

    p {
      color: $light-black;
      font-size: 1rem;
      padding: 0 120px 30px 120px;
    }
  }

  .mister-pic {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (max-width: $md-desktops) {
    grid-template-columns: 1fr;

    .mister-vickers-text {
      text-align: center;
      padding: 20px;

      .mister-vickers-header {
        padding: 0 12px;
        font-size: 1.75rem;
      }

      p {
        padding: 0 10vw;
        font-size: .75rem;
      }
    }
  }
}