@import "../../../variables.scss";

.formRowLabel {
  width: 15%;
  min-width: 25%;
  padding: 11px 12px;
  color: $tan;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;

  @media screen and (max-width: $xl-phones) {
    min-width: 45%;
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.formRowInput:-webkit-autofill {
  -webkit-text-fill-color: $white;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.formRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
  font-family: $montserrat;
}

.formRowInput::placeholder {
  font-family: $electro;
  font-weight: 800;
  color: $brown;
}