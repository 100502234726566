@import '../../../variables.scss';

.about-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .about-pic {
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width : $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width : $phones) {
    grid-template-columns: 1fr;
  }
}