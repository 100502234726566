@import '../../../variables.scss';

.live-header {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;

  background: url('https://storage.googleapis.com/dj-scrib.appspot.com/images/live.png') $scrib-gold;
  background-size: cover;
  background-position: center;
  box-shadow: $box-shadow;
  opacity: 0.8;

  color: $scrib-gold;
  text-align: left;


  .live-header-title {
    margin: 0;
    text-transform: uppercase;
    font-family: $electro;
    font-size: 5rem;
    font-weight: 100;
    line-height: .8;
    letter-spacing: -5px;
  }

  @media only screen and (max-width: $tablet) {
    .live-header-title {
      font-size: 6rem;
    }
  }
}

@media only screen and (max-width: $phones) {
  .live-header {
    opacity: 0.7;

    .live-header-title {
      font-size: 4rem;
      margin-left: 0;
    }
  }
}